<template>
  <section class="container pb-8">
    <div class="error-bg mb-3 mb-md-8" v-if="PageInfo.data.length === 0">
      <div>查無資料</div>
    </div>
    <ul  class="mb-3" v-else-if="PageInfo.data.length !== 0">
      <li
        v-for="(item, index) in PageInfo.data"
        :key="index"
        class="exam-category"
      >
        <h5>{{ item.knowledgeName }}</h5>

        <div class="exam-knowledge__progress">
          <el-progress
            :text-inside="true"
            :stroke-width="20"
            :percentage="Percentage(item)"
          />
          <span>{{ item.questionCount }}/{{ item.questionTotal }}</span>
          <!-- <a class="btn btn-info btn-sm ml-2 text-nowrap" @click="gotoExam(item)">定點測試</a> -->
        </div>
      </li>
    </ul>

    <!-- 分頁 -->
    <Pagination
      @GetList="GetList()"
      :page.sync="Pagination.pageIndex"
      :page-row="Pagination.pageSize"
      :total-count="PageInfo.count"
    />
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { GetPracticeSbujectBreadcrumb, GetPracticeKnowledgeByMid } from '@/api/BuyPracticeApi';

export default {
  components: {
    Pagination,
  },
  data() {
    // 建立你的初始化 model data
    return {
      subjectId: 0,
      Args: { subjectId: '' },
      Pagination: {
        // 有分頁要有此model data
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 10, // 預設每頁幾筆
        data: {
          subjectId: '',
        },
      },
      PageInfo: {
        count: 0, // api給的總筆數
        data: [],
      },
    };
  },
  // 當有設定keep的時候，返回到此頁面會執行這個，不會執行created()，所以這邊就不用放created()
  async activated() {
    const { id } = this.$route.params;
    this.subjectId = id;
    if (!this.$route.meta.isBack) {
      // 不是從指定頁面返回的話，進行初始化設定
      this.Args = { subjectId: id };
      this.Pagination = {
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 10, // 預設每頁幾筆
        data: {
          subjectId: id,
        },
      };
      this.PageInfo = {
        count: 0, // api給的總筆數
        data: [],
      };
    }
    // 然後再針對頁面進行初始化資料存取
    this.GetList();
    this.GetBreadcrumb();
  },
  methods: {
    GetBreadcrumb() {
      const postData = {
        Id: this.$route.params?.id,
      };

      GetPracticeSbujectBreadcrumb(postData)
        .then((response) => {
          if (response.data.statusCode === 0) {
            const { data } = response.data;

            if (!this._.isEmpty(data)) {
              this.$emit('update:currentPage', [
                {
                  name: 'practicesCategory',
                  title: `${data.subJectName}`,
                  path: `/practices/${data.subjectId}`,
                },
                {
                  name: 'knowledge',
                  title: '知識點分析',
                  path: '',
                },
              ]);
            }
          }
        });
    },
    GetList() {
      const { id } = this.$route.params;

      this.Args = { subjectId: id };
      this.Pagination.data = { ...this.Args };
      GetPracticeKnowledgeByMid(this.Pagination).then((response) => {
        if (response.data.statusCode === 0) {
          this.PageInfo.count = response.data.count;
          this.PageInfo.data = response.data.data;

          if (this.Pagination.pageIndex !== 1 && this.PageInfo.data.length === 0) {
            this.Pagination.pageIndex = 1;
            this.GetList();
          }
        }
      });
    },
    Percentage(item) {
      const percentage = Math.round((item.questionCount / item.questionTotal) * 100);

      // eslint-disable-next-line no-restricted-globals
      return isNaN(percentage) ? 0 : percentage;
    },
    gotoExam(item) {
      this.$router.push({
        path: `/practices/exam/${this.subjectId}`,
        query: {
          // 題目數量
          questionCount: item.questionTotal,
          // 知識點分類編號，可多個，否的話給空
          knowledgeIds: item.knowledgeId,
        },
      });
    },
  },
};
</script>

<style></style>
